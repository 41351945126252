import React from "react";
import theme from "theme";
import { Theme, Link, List, Section, Box, Strong, Text, Icon, LinkBox, Span, Image, Em, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import { FaTwitterSquare, FaGithub, FaYoutube, FaFacebook, FaInstagram } from "react-icons/fa";
import { MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Hotel Kasyno Royale
			</title>
			<meta name={"description"} content={"Hotel Kasyno Royale to ekskluzywny obiekt położony w sercu Warszawy. Oferujemy najwyższy standard usług i komfortowe pokoje, które zapewnią niezapomniane wrażenia. Nasze kasyno to idealne miejsce na rozrywkę i relaks."} />
			<meta property={"og:title"} content={"Hotel Kasyno Royale"} />
			<meta property={"og:description"} content={"Hotel Kasyno Royale to ekskluzywny obiekt położony w sercu Warszawy. Oferujemy najwyższy standard usług i komfortowe pokoje, które zapewnią niezapomniane wrażenia. Nasze kasyno to idealne miejsce na rozrywkę i relaks."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/Bellagio_Casino_and_Hotel_at_Night.jpg?v=2024-07-26T12:53:23.317Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/Bellagio_Casino_and_Hotel_at_Night.jpg?v=2024-07-26T12:53:23.317Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/Bellagio_Casino_and_Hotel_at_Night.jpg?v=2024-07-26T12:53:23.317Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/Bellagio_Casino_and_Hotel_at_Night.jpg?v=2024-07-26T12:53:23.317Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/Bellagio_Casino_and_Hotel_at_Night.jpg?v=2024-07-26T12:53:23.317Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/Bellagio_Casino_and_Hotel_at_Night.jpg?v=2024-07-26T12:53:23.317Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/Bellagio_Casino_and_Hotel_at_Night.jpg?v=2024-07-26T12:53:23.317Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Section background-color="--dark" text-align="center" padding="32px 0" quarkly-title="Footer-1">
			<List
				margin="0px 0px 0px 0px"
				padding="12px 0px 12px 0px"
				list-style-type="none"
				as="ul"
				display="flex"
				align-items="center"
				justify-content="center"
			>
				<Link
					href="#about"
					color="white"
					padding="6px 12px 6px 12px"
					text-decoration-line="initial"
					display="flex"
					font="--lead"
					hover-color="--lightD2"
					transition="background-color 0.1s ease 0s"
				>
					O hotelu
				</Link>
				<Link
					href="#offers"
					color="white"
					padding="6px 12px 6px 12px"
					text-decoration-line="initial"
					display="flex"
					font="--lead"
					hover-color="--lightD2"
					transition="background-color 0.1s ease 0s"
				>
					Nasza oferta
				</Link>
				<Link
					href="#casino"
					color="white"
					padding="6px 12px 6px 12px"
					text-decoration-line="initial"
					display="flex"
					font="--lead"
					hover-color="--lightD2"
					transition="background-color 0.1s ease 0s"
				>
					Kasyno
				</Link>
				<Link
					href="#contacts"
					color="white"
					padding="6px 12px 6px 12px"
					text-decoration-line="initial"
					display="flex"
					font="--lead"
					hover-color="--lightD2"
					transition="background-color 0.1s ease 0s"
				>
					Kontakty
				</Link>
			</List>
		</Section>
		<Section
			background="--color-dark"
			padding="50px 0 50px 0"
			quarkly-title="Footer-19"
			border-width="0 0 .2px 0"
			border-style="solid"
			border-color="--color-darkL1"
		>
			<Box display="flex" justify-content="space-between" border-color="#232a44" md-flex-direction="column">
				<Box
					display="flex"
					md-margin="0px 0px 15px 0px"
					sm-flex-direction="column"
					justify-content="flex-start"
					align-items="center"
					grid-gap="24px"
				>
					<Box display="flex" grid-gap="8px">
						<Link
							href="tel:+48225849650"
							color="--light"
							text-decoration-line="initial"
							font="normal 300 16px/1.5 --fontFamily-sans"
							display="block"
							margin="0px 0px 0px 0px"
							sm-margin="0px 0 5px 0px"
							hover-color="#a78bfa"
						>
							Zadzwoń do nas +48223462900
						</Link>
					</Box>
					<Box display="flex" grid-gap="8px">
						<Text margin="0px 0px 0px 0px" color="white" font="--base">
							<Strong>
								al. Jerozolimskie 65/79, 00-001 Warszawa, Poland
							</Strong>
						</Text>
					</Box>
				</Box>
				<Box display="flex" grid-template-columns="repeat(5, 1fr)" grid-gap="16px 24px" md-align-self="flex-start">
					<LinkBox href="/">
						<Icon
							category="fa"
							icon={FaTwitterSquare}
							size="24px"
							color="#c3c8d0"
							hover-color="#a78bfa"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
					<LinkBox href="/">
						<Icon
							category="fa"
							icon={FaGithub}
							size="24px"
							color="#c3c8d0"
							hover-color="#a78bfa"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
					<LinkBox href="/">
						<Icon
							category="fa"
							icon={FaYoutube}
							size="24px"
							color="#c3c8d0"
							hover-color="#a78bfa"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" id="about">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
			>
				<Text
					font="--headline1"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Hotel Kasyno Royale
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					<Span
						word-break="normal"
						text-indent="0"
						color="--green"
						overflow-wrap="normal"
						white-space="normal"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Hotel Kasyno Royale
					</Span>
					{" "}to ekskluzywny obiekt położony w sercu Warszawy. Oferujemy najwyższy standard usług i komfortowe pokoje, które zapewnią niezapomniane wrażenia. Nasze kasyno to idealne miejsce na rozrywkę i relaks.
				</Text>
			</Box>
		</Section>
		<Section padding="180px 0 184px 0" background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(110, 37, 189, 0.51) 0%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/Bellagio_Casino_and_Hotel_at_Night.jpg?v=2024-07-26T12:53:23.317Z) 0% 0% /cover repeat scroll padding-box" sm-padding="120px 0 124px 0" quarkly-title="Hero-15">
			<Text
				margin="0px 0px 50px 0px"
				text-align="center"
				font="normal 700 64px/1.2 --fontFamily-sans"
				letter-spacing="1px"
				color="--light"
				padding="0px 250px 0px 250px"
				lg-padding="0px 0 0px 0"
				md-font="normal 700 42px/1.2 --fontFamily-sans"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
			>
				Poznaj historię Kasyna Royale
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				color="--light"
				text-align="center"
				padding="0px 250px 0px 250px"
				font="--lead"
				lg-padding="0px 0 0px 0"
			>
				Hotel Kasyno Royale to miejsce, gdzie historia spotyka się z nowoczesnością. Nasze kasyno oferuje szeroki wybór gier, które dostarczą niezapomnianych emocji i wrażeń. Profesjonalna obsługa i luksusowe wnętrza sprawią, że każda wizyta będzie wyjątkowa.
			</Text>
		</Section>
		<Section padding="120px 0 120px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Najlepsze oferty Hotelu Kasyna Royale
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Nasze ekskluzywne oferty to gwarancja niezapomnianych wrażeń. Zarezerwuj pobyt w Hotelu Kasyna Royale i ciesz się luksusem oraz wyjątkowymi atrakcjami. Oferujemy różnorodne pakiety, które spełnią oczekiwania każdego gościa.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/475518937.jpg?v=2024-07-26T12:53:23.282Z"
					width="100%"
					max-height="293px"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/475518937.jpg?v=2024-07-26T12%3A53%3A23.282Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/475518937.jpg?v=2024-07-26T12%3A53%3A23.282Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/475518937.jpg?v=2024-07-26T12%3A53%3A23.282Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/475518937.jpg?v=2024-07-26T12%3A53%3A23.282Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/475518937.jpg?v=2024-07-26T12%3A53%3A23.282Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/475518937.jpg?v=2024-07-26T12%3A53%3A23.282Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/475518937.jpg?v=2024-07-26T12%3A53%3A23.282Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="60px 0 40px 0" lg-padding="50px 0 25px 0" md-padding="30px 0 25px 0">
			<Override slot="SectionContent" align-items="flex-end" />
			<Text
				margin="0px 0px 16px 0px"
				color="--light"
				font="--headline2"
				lg-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
				md-font="normal 500 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
				width="100%"
			>
				Nasze Kasyno
			</Text>
			<Text
				color="--light"
				font="--headline2"
				lg-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
				width="100%"
				margin="0px 0px 0px 0px"
			>
				<Em>
					<Strong>
						Ekscytujące Kasyno na Miejscu
					</Strong>
				</Em>
			</Text>
			<Box
				empty-min-height="64px"
				empty-border-color="LightGray"
				width="50%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				margin="40px 0px 0px 0px"
				lg-width="70%"
				md-width="100%"
			>
				<Text margin="0px 0px 16px 0px" color="--light" font="--base" text-align="left">
					Hotel Kasyno Royale zaprasza do naszego nowoczesnego kasyna, które oferuje niezrównane doświadczenia hazardowe. Nasze kasyno jest wyposażone w najnowsze automaty do gier, stoły do blackjacka, ruletki, pokera i wiele innych. Dla tych, którzy cenią sobie ekskluzywność, oferujemy także prywatne pokoje do gry, gdzie można cieszyć się grą w bardziej kameralnej atmosferze. Nasze profesjonalne krupierzy zapewnią, że każda gra przebiega płynnie i zgodnie z najwyższymi standardami.
				</Text>
				<Box display="grid" grid-template-columns="1fr 1fr" grid-gap="0px 40px">
					<Box>
						<Image
							src="https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/L116336.webp?v=2024-07-26T12:53:23.289Z"
							max-height="120px"
							width="100%"
							object-fit="cover"
							lg-height="120px"
							srcSet="https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/L116336.webp?v=2024-07-26T12%3A53%3A23.289Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/L116336.webp?v=2024-07-26T12%3A53%3A23.289Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/L116336.webp?v=2024-07-26T12%3A53%3A23.289Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/L116336.webp?v=2024-07-26T12%3A53%3A23.289Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/L116336.webp?v=2024-07-26T12%3A53%3A23.289Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/L116336.webp?v=2024-07-26T12%3A53%3A23.289Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/L116336.webp?v=2024-07-26T12%3A53%3A23.289Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text color="--green" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="8px 0px 0px 0px" font="--lead">
							Turnieje i Wydarzenia Specjalne
						</Text>
					</Box>
					<Box>
						<Image
							lg-height="120px"
							src="https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/433572977.jpg?v=2024-07-26T12:53:23.296Z"
							max-height="120px"
							width="100%"
							object-fit="cover"
							srcSet="https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/433572977.jpg?v=2024-07-26T12%3A53%3A23.296Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/433572977.jpg?v=2024-07-26T12%3A53%3A23.296Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/433572977.jpg?v=2024-07-26T12%3A53%3A23.296Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/433572977.jpg?v=2024-07-26T12%3A53%3A23.296Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/433572977.jpg?v=2024-07-26T12%3A53%3A23.296Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/433572977.jpg?v=2024-07-26T12%3A53%3A23.296Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/433572977.jpg?v=2024-07-26T12%3A53%3A23.296Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text margin="8px 0px 0px 0px" font="--lead" color="--green" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif">
							Bar Kasynowy i Lounge
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 100px 0"
			display="flex"
			flex-direction="column"
			background="#16191b"
			quarkly-title="Cards-2"
			id="offers"
		>
			<Override slot="SectionContent" />
			<Text margin="0px 0px 25px 0px" font="normal 500 52px/1.2 --fontFamily-serifGaramond" text-align="center" color="--light">
				Przygoda zaczyna się
			</Text>
			<Text
				color="--light"
				font="--base"
				lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
				margin="0px 0px 0px 0px"
				text-align="left"
				max-width="80%"
				align-self="center"
			>
				Każdego ranka powitają Cię aromaty świeżo parzonej kawy i odgłosy rwącej wody, które mieszają się z szeptem budzącego się do życia miasta. Śniadanie jest gorące i różnorodne, czekające na Ciebie. Po śniadaniu, pomóż w załadowaniu ekwipunku z powrotem do łodzi.
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				width="300px"
				align-self="center"
				border-color="#a36c51"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				lg-flex-direction="column"
				sm-width="100%"
			>
				<Box
					min-width="100px"
					min-height="100px"
					margin="0px 0px 0 0"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,#000000 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/16256-140839-f71067308_3xl.webp?v=2024-07-26T12:53:23.286Z) center/cover repeat scroll padding-box"
					height="500px"
					width="65%"
					lg-width="auto"
					lg-margin="0px 0px 30px 0"
					lg-height="400px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						align-items="center"
						margin="0px 0px 20px 0px"
						lg-margin="0px 0px 15px 0px"
						sm-flex-direction="column"
						sm-align-items="flex-start"
					>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							align-items="center"
							border-color="--color-lightD2"
							padding="0 0px 0px 0px"
							border-width={0}
							border-style="solid"
							margin="0px 18px 0px 0px"
							sm-margin="0px 18px 6px 0px"
						>
							<Text margin="0px 0 0px 10px" font="normal 500 16px/1.5 --fontFamily-sansHelvetica" sm-font="normal 500 18px/1.5 --fontFamily-sans" color="#bec4ca">
								Hotel Kasyno Royale oferuje luksusowe pokoje i apartamenty, które zapewniają najwyższy komfort i wygodę. Każdy pokój jest elegancko urządzony, wyposażony w najnowsze udogodnienia, w tym telewizory z płaskim ekranem, bezpłatne Wi-Fi oraz mini-bary. Niezależnie od tego, czy podróżujesz w celach biznesowych, czy rekreacyjnych, nasze pokoje zapewnią ci

 relaksujący i spokojny pobyt. Zapraszamy do skorzystania z naszej obsługi pokojowej dostępnej przez całą dobę.
							</Text>
						</Box>
					</Box>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/" lg-margin="0px 0px 0 0px">
						<Text margin="0px 0px 0 0px" font="normal 500 25px/1.2 --fontFamily-serifGaramond" color="--light">
							Luksusowe Zakwaterowanie
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					grid-template-columns="repeat(3, 1fr)"
					grid-gap="0 50px"
					lg-grid-gap="35px 0"
					md-grid-template-columns="1fr"
					lg-grid-template-columns="1fr"
					flex-direction="column"
					margin="0px 0px 0px 15px"
					width="35%"
					lg-width="auto"
					lg-margin="0px 0px 0px 0"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 0 0px"
						margin="0px 0px 0 0"
						display="flex"
						flex-direction="column"
						justify-content="flex-end"
						padding="30px 30px 30px 30px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,#000000 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/img-7.jpg?v=2024-07-26T13:05:23.018Z) center/cover repeat scroll padding-box"
						height="250px"
						lg-height="300px"
					>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							align-items="center"
							margin="0px 0px 20px 0px"
							lg-margin="0px 0px 15px 0px"
							sm-align-items="flex-start"
							sm-flex-direction="column"
						>
							<Box
								min-width="10px"
								min-height="10px"
								display="flex"
								align-items="center"
								border-color="--color-lightD2"
								padding="0 0px 0px 0px"
								border-width={0}
								border-style="solid"
								margin="0px 18px 0px 0px"
								sm-margin="0px 18px 6px 0px"
							>
								<Text margin="0px 0 0px 10px" font="normal 500 16px/1.5 --fontFamily-sansHelvetica" sm-font="normal 500 18px/1.5 --fontFamily-sans" color="#bec4ca">
									Hotel Kasyno Royale szczyci się wyśmienitą ofertą kulinarną. Nasze restauracje serwują dania kuchni międzynarodowej i lokalnej, przygotowywane przez wykwalifikowanych szefów kuchni.{" "}
								</Text>
							</Box>
						</Box>
						<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/" lg-margin="0px 0px 0 0px">
							<Text margin="0px 0px 0 0px" font="normal 500 25px/1.2 --fontFamily-serifGaramond" color="--light">
								Wyśmienite Restauracje i Bary
							</Text>
						</LinkBox>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 0 0px"
						margin="0px 0px 0 0"
						display="flex"
						flex-direction="column"
						justify-content="flex-end"
						padding="30px 30px 30px 30px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,#000000 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/top10hotelspas-theretreatspa-1572490634.jpg?v=2024-07-26T12:53:23.284Z) center/cover repeat scroll padding-box"
						height="250px"
						lg-height="300px"
					>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							align-items="center"
							margin="0px 0px 20px 0px"
							lg-margin="0px 0px 15px 0px"
							sm-align-items="flex-start"
							sm-flex-direction="column"
						>
							<Box
								min-width="10px"
								min-height="10px"
								display="flex"
								align-items="center"
								border-color="--color-lightD2"
								padding="0 0px 0px 0px"
								border-width={0}
								border-style="solid"
								margin="0px 18px 0px 0px"
								sm-margin="0px 18px 6px 0px"
							>
								<Text margin="0px 0 0px 10px" font="normal 500 16px/1.5 --fontFamily-sansHelvetica" sm-font="normal 500 18px/1.5 --fontFamily-sans" color="#bec4ca">
									Odkryj nasz luksusowy basen i spa, idealne miejsce na relaks i odnowę biologiczną. Nasz basen z podgrzewaną wodą jest dostępny przez cały rok, a leżaki wokół basenu zapewniają doskonałe miejsce do opalania się.{" "}
								</Text>
							</Box>
						</Box>
						<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/" lg-margin="0px 0px 0 0px">
							<Text margin="0px 0px 0 0px" font="normal 500 25px/1.2 --fontFamily-serifGaramond" color="--light">
								Basen i SPA
							</Text>
						</LinkBox>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0"
			sm-padding="40px 0"
			background="--color-darkL2"
			quarkly-title="About-8"
			id="casino"
		>
			<Override slot="SectionContent" flex-direction="row" lg-flex-wrap="wrap" sm-min-width="100%" />
			<Box
				width="70%"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				padding="90px 64px 90px 64px"
				background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(110, 37, 189, 0.51) 0%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/433572977.jpg?v=2024-07-26T12:53:23.296Z) 0% 0% /cover repeat scroll padding-box"
				lg-width="100%"
				sm-padding="32px 32px 32px 32px"
				lg-padding="48px 64px 90px 64px"
				md-padding="48px 48px 90px 48px"
			>
				<Text margin="0px 0px 24px 0px" font="--headline1" color="--light" sm-font="--headline2">
					O Hotelu Kasyno Royale
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					font="--base"
					color="--light"
					max-width="400px"
					sm-max-width="none"
				>
					Hotel Kasyno Royale to ikona luksusu i rozrywki, położona w sercu Warszawy. Nasz obiekt oferuje nie tylko komfortowe pokoje, ale także ekskluzywne kasyno, które dostarcza niezapomnianych emocji. Doświadcz najwyższego standardu usług i zrelaksuj się w naszej nowoczesnej strefie spa. Zapraszamy do odkrycia magii Hotelu Kasyno Royale.
				</Text>
				<Link
					href="#"
					color="--dark"
					text-decoration-line="initial"
					padding="9px 24px 10px 24px"
					background="--color-light"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					letter-spacing="0.5px"
					border-radius="6px"
				>
					Dowiedz się więcej
				</Link>
			</Box>
			<Box
				width="30%"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				position="relative"
				justify-content="center"
				lg-width="100%"
				lg-justify-content="flex-start"
				background="#1B2628"
			>
				<Image
					src="https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/L116336.webp?v=2024-07-26T12:53:23.289Z"
					display="block"
					max-height="80%"
					position="absolute"
					left="-200px"
					width="500px"
					height="400px"
					object-fit="cover"
					object-position="0% 100%"
					lg-position="static"
					lg-width="100%"
					lg-height="auto"
					lg-max-height="600px"
					sm-max-height="400px"
					transform="skewY(-10deg)"
					srcSet="https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/L116336.webp?v=2024-07-26T12%3A53%3A23.289Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/L116336.webp?v=2024-07-26T12%3A53%3A23.289Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/L116336.webp?v=2024-07-26T12%3A53%3A23.289Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/L116336.webp?v=2024-07-26T12%3A53%3A23.289Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/L116336.webp?v=2024-07-26T12%3A53%3A23.289Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/L116336.webp?v=2024-07-26T12%3A53%3A23.289Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a398d6c2c6080018ea62b1/images/L116336.webp?v=2024-07-26T12%3A53%3A23.289Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="90px 0 50px 0"
			background="--color-dark"
			quarkly-title="Footer-13"
			sm-padding="50px 0 50px 0"
			id="contacts"
		>
			<Box
				min-width="100px"
				min-height="100px"
				padding="50px 30px 50px 30px"
				background="linear-gradient(180deg,rgba(11, 1, 1, 0.67) 0.5%,rgba(11, 7, 7, 0.45) 99%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66a398d6c2c6080018ea62b1/images/Spa_couple_web.jpg?v=2024-07-26T12:53:23.291Z) bottom/auto repeat scroll padding-box"
				display="flex"
				justify-content="space-around"
				align-items="center"
				margin="0px 0px 80px 0px"
				md-flex-direction="column"
				md-align-items="stretch"
				md-margin="0px 0px 50px 0px"
				md-padding="30px 30px 30px 30px"
				sm-padding="20px 15px 20px 15px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					md-margin="0px 0px 30px 0px"
					md-width="100%"
					sm-margin="0px 0px 18px 0px"
				>
					<Text
						margin="0px 0px \\n\\n20px 0px"
						font="normal 400 42px/1.2 --fontFamily-sansHelvetica"
						color="--light"
						md-margin="0px 0px 15px 0px"
						sm-margin="0px 0px 12px 0px"
					>
						Zadzwoń po rezerwację
					</Text>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 300 20px/1.5 --fontFamily-sans">
						Jesteśmy otwarci w godz
Od poniedziałku do piątku (10:00 – 18:00) i soboty (10:00 – 18:00)
					</Text>
				</Box>
				<LinkBox
					flex-direction="row"
					padding="15px 25px 15px 25px"
					border-width="2px"
					border-style="solid"
					border-color="--color-light"
					background="rgba(247, 251, 255, 0.12)"
					sm-padding="10px 15px 10px 15px"
					href="tel:+48223462900"
				>
					<Icon
						category="md"
						icon={MdPhone}
						size="24px"
						color="--light"
						margin="0px 8px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 500 20px/1.5 --fontFamily-sans" color="--light">
						+48223462900
					</Text>
				</LinkBox>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0px 80px 0px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
				md-margin="0px 0px 50px 0px"
			>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 25px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="--green"
						md-margin="0px 0px 20px 0px"
					>
						Adres
					</Text>
					<Text
						margin="0px 0px 20px 0px"
						color="#d7c7c5"
						font="normal 300 18px/1.5 --fontFamily-sans"
						text-align="center"
						border-color="#b8acac"
						md-margin="0px 0px 15px 0px"
					>
						Chmielna 71, 00-801 Warszawa, Poland
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						grid-template-columns="repeat(5, 1fr)"
						grid-gap="16px 24px"
						md-align-self="flex-start"
						sm-align-self="center"
						justify-items="start"
						justify-content="center"
					>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaFacebook}
								size="24px"
								color="--light"
								hover-color="--lightD2"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaInstagram}
								size="24px"
								color="--light"
								hover-color="--lightD2"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaTwitterSquare}
								size="24px"
								color="--light"
								hover-color="--lightD2"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Text
						margin="0px 0px 25px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="--green"
						md-margin="0px 0px 20px 0px"
					>
						Rezerwacja
					</Text>
					<Link
						href="tel:+48225849650"
						color="#d7c7c5"
						text-decoration-line="initial"
						font="normal 300 18px/1.5 --fontFamily-sans"
						display="flex"
						text-align="center"
						margin="0px 0px 15px 0px"
						hover-color="#f5eceb"
					>
						+48223462900
					</Link>
					<Link
						href="mailto:info@yourdomain.com"
						color="#d7c7c5"
						text-decoration-line="initial"
						font="normal 300 18px/1.5 --fontFamily-sans"
						display="flex"
						margin="0px 0px 15px 0px"
						text-align="center"
						hover-color="#f5eceb"
					>
						info@braliver-hotel.com
					</Link>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 25px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="--green"
						md-margin="0px 0px 20px 0px"
					>
						Godzina otwarcia
					</Text>
					<Text
						margin="0px 0px 15px 0px"
						color="#d7c7c5"
						font="normal 300 18px/1.5 --fontFamily-sans"
						text-align="center"
						border-color="#b8acac"
					>
						Od poniedziałku do piątku (10:00 – 23:00)
					</Text>
					<Text
						margin="0px 0px 20px 0px"
						color="#d7c7c5"
						font="normal 300 18px/1.5 --fontFamily-sans"
						text-align="center"
						border-color="#b8acac"
					>
						Sobota (10:00 – 22:00)
					</Text>
				</Box>
			</Box>
			<Text
				margin="0px 0px 0px 0px"
				font="normal 300 18px/1.5 --fontFamily-sans"
				color="#d7c7c5"
				md-margin="0px 0px 25px 0px"
				sm-text-align="center"
				text-align="center"
			>
				© 2024 Hotel Kasyno Royale All rights reserved.
			</Text>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});